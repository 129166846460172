.page-provider-profile {

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1D1D1D;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 10px;
    margin-top: 35px;
    margin-bottom: 30px;
  }

  h4 {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #1D1D1D;
    margin-bottom: 0;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 125px 0;
    width: 100%;
  }

}
