.integrations-list {

  align-items: stretch;

  p {
    font-size: 14px;
    margin: 0 0 25px 0;
    text-align: left;
  }

  .ant-col {
    margin-bottom: 20px;
  }

  .ant-card {
    position: relative;
    border-radius: 0;
    border: 1px solid #FFFFFF;
    overflow: hidden;
    transition: all .15s ease;
    height: 100%;

    &:hover {
      border: 1px solid #00e2a7;
      box-shadow: 0 4px 12px rgba(0,226,167,.15), inset 0 0 0 1px #00e2a7;
      cursor: pointer;
    }

    &.no-hover {

      &:hover {
        border: 1px solid #FFFFFF;
        box-shadow: none;
        cursor: auto;
      }

    }

    &.empty {
      background-color: transparent;
      border: 2px dashed #CCD0D6;
      box-shadow: none;
      min-height: 260px;

      .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      i {
        font-size: 40px;
        color: #848991;
      }

      p {
        margin: 20px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #848991;
      }

    }

  }

  img {
    display: block;
    width: 100%;
    margin: 0 auto 25px auto;
  }

  &--label {
    position: absolute;
    bottom: 15px;
    font-weight: 500;
    font-size: 11px;
    margin-bottom: 5px;
    padding-left: 15px;
    color: orange;

    &.active {
      color: #00e2a7;
    }

    &.soon {
      color: #d8d8d8;
    }

    &:before {
      position: absolute;
      top: 38%;
      transform: translateY(-50%);
      left: 0;
      content: '•';
      font-size: 30px;
      height: auto;
    }

  }

}
