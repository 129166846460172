.ant-card {
  border-radius: 8px;
  border: 1px solid #FFFFFF;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);

  .ant-card-head-title {

    h1, h2, h3, h4, h5 ,h6 {
      margin: 0;
    }

  }

}