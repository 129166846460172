.page-onboarding-biography {

  max-width: 1050px;
  margin: 0 auto;

  .title-block {

    h2, p {
      text-align: left;
    }

  }

  .ant-form-item-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.5px;
    margin-bottom: 5px;
    color: #222222;
  }

  .button.primary {
    width: 100%;
  }

  .ant-input:not(textarea) {
    height: 40px;
  }

}