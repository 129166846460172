.page.page-share {

  .ant-form-item label {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  .share-link {
    max-width: 475px;
  }

  .clipboard {
    color: #00e2a7;
    font-weight: bold;
    font-size: 11px;
    line-height: 25px;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.3s ease;
    margin: 0;
    padding: 0;

    &.copied {
      opacity: 1;
      transform: translateY(0);
    }

  }

  .preview-card {
    background-color: #E2E5EA;
    border-radius: 4px;
    padding: 12px;

    .ant-btn {
      margin-right: 8px;
      min-width: 174px;
      margin-top: 12px;  
      
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .preview-image {
      border-radius: 4px;
      image-rendering: -webkit-optimize-contrast;
      margin-bottom: 0;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1D1D1D;
    padding-bottom: 10px;
    margin-top: 35px;
    margin-bottom: 25px;
  }

}
