.page-settings {

  h2 {
    font-size: 20px;
    color: rgba(0,0,0,.85);
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 12px;
    display: block;
  }

  .primary-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: auto;
  }

  .ant-layout-content {
    display: flex;
  }

  .ant-menu-inline {
    border: none;
    box-shadow: none;
  }

  .menu-container {
    width: 224px;
    border-right: 1px solid #e8e8e8;
    background-color: #FFF;
    z-index: 1;
  }

  .info-right {
    flex: 1 1;
    padding: 8px 40px;
  }

  .share-url {
    position: relative;
    padding: 20px;
    max-width: 80%;
    background-color: #e5edff;
    border-radius: 8px;
    border: 1px dashed #5285FF;

    &--container {
      position: relative;
      margin-top: 20px;

      &:before {
        content: "";
        position: absolute;
        width: 80%;
        height: 110%;
        top: -25px;
        left: -15px;
        background-color: #f3f3f7;
      }

    }

    & > div {
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 15px;
    }

    input {
      border-radius: 5px;
      margin-right: 10px;
      width: 75%;
      border: 1px solid rgba(43, 145, 254, 0.5);
      padding: 5px 10px;
      color: #5285fe;
      font-weight: 500;
    }

    button {
      background-color: #5285fe;
      border: none;
      padding: 7px 40px;
      border-radius: 5px;
      color: #fff;
      outline: none;
    }

  }

  .profile-view-left {
    max-width: 448px;
    min-width: 224px;
  }

  .upload-block {
    padding: 50px 0;

    &--logo {
      max-width: 100%;
      text-align: center;
    }

    &--avatar {
      border-radius: 50%;
      max-width: 100%;
      text-align: center;
    }

    &--images {
      display: flex;
      align-items: stretch;

      & > .ant-col {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 0 20px;

        .ant-row {
          align-items: center;
        }

        img {
          margin-bottom: 25px;
        }

      }

    }

    &--asset,
    &--buttons {
      display: flex;
      justify-content: center;
    }

    &--label {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 15px;
    }

  }

  .ant-card {
    max-width: 500px;
    box-shadow: none;
    border: 1px solid #e8e8e8;
    border-radius: 0;
    margin-bottom: 25px;

    h3 {
      font-size: 14px;

      span {
        font-weight: 300;
      }

    }

  }

  .subscription {

    button {
      margin-top: 15px;
    }

    .subscription-details {

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        margin-bottom: 10px;

        b {
          font-weight: 600;
        }

        &.warning {
          color: #F25D5D;
        }

      }

    }

    .button.primary {
      display: inline-block;
      font-size: 13px;
      padding: 7px 25px;
      margin: 0;
    }

  }

  .payment-method {

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {

        b {
          font-weight: 500;
        }

      }

    }

    .current-method {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;

      svg {
        margin-left: 5px;
      }

    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-input.StripeElement {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & > div {
        width: 100%;
      }

    }

    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 45px;
    }

    button {
      margin-top: 30px;
    }

  }

  .badge {
    padding: 2px 10px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .28px;
    border-radius: 13px;
    color: #fff;
    background-color: #00e2a7;
  }

  .ant-upload-list {
    display: none;
  }

}

.ReactCrop__crop-selection {
  border-radius: 50%;
  border: none!important;
}
