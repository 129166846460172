.page-signup {

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -.5px;
    margin-bottom: 25px;
  }

  .ant-card {

    .ant-form-item-label {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.5px;
      margin-bottom: 5px;
      color: #222222;
    }

    .ant-select-selection {
      border-radius: 4px;
    }

    .ant-select-selection__rendered {
      line-height: 40px;
    }

    .ant-input-group-addon {
      padding: 0 15px;
    }

  }

  .login {
    text-align: center;
    padding: 25px 0;
  }

  .form-button {
    margin: 0;

    .button.primary {
      margin-top: 0px;
    }
  }

}
