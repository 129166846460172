html,
body {
  background: #f0f2f5;
}

.provider-select {

  .ant-select-selection--single {
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 0;
    position: relative;
    height: auto;
    outline:none;


    &:hover {
      background: #f9f9f9;
    }

  }

  .ant-select-selection__rendered {
    margin: 0;
  }

  &--item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

  }

  &--avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #222222;
    color: #FFFFFF;
    margin-right: 15px;

    span {
      position: relative;
      top: 1px;
    }

  }

  &--details {
    font-weight: 500;
    font-size: 14px;
    color: #222222;
  }

  &--option {
    padding: 1rem;

    &.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #E0FBF4;
    }

  }

  p {
    margin-top: 0;
  }

}

.table-empty-state {
  margin: 75px 0;
  text-align: center;

  p {
    color: #222222;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;
  }

  .button.primary {
    display: inline-block;
    width: auto;
  }

}
