.followup-module {

  .field-title,
  .field-type {
    display: block;
  }

  .field-title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    font-size: 14px;
  }

  .field-type {
    font-size: 12px;
  }

  .module--table-row {

    .module--table-column:first-child {
      max-width: 350px;
    }

    .module--table-column {
      padding: 16px 24px;
      flex: 1 1 0;
    }

  }

  .ant-row {

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column-reverse;
    }

  }

  .ant-card {
    border: none;
    margin-bottom: 25px;
    border-radius: 0;

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }

    ul {
      margin: 15px 0;
      padding-left: 16px;

      li {
        margin-bottom: 5px;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
      }

    }

  }

  .ant-card-body {
    padding: 0;
  }

  .card {

    &--inner {
      padding: 24px;
    }

  }

  .ant-collapse {

    border-radius: 0;
    border: none;

    .ant-collapse-item:last-child {

      border-radius: 0;
      border: none;

      & > .ant-collapse-header {
        border-radius: 0;
        border: none;
      }

    }

  }

}
