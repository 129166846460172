.page-rewards {

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 20px;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);

    &.subtext {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.85);
    }

  }

  .ant-card {
    margin-bottom: 25px;
    outline: 2px solid transparent;

    &.locked {
      outline-color: #FFB930;
      box-shadow: 0px 6px 15px rgba(255, 185, 48, 0.15);
    }

  }

  .ant-card-body {
    padding: 0;

    .card--inner {
      padding: 24px;

      &-status {
        width: 100%;
        min-height: 53px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #e8e8e8;
        background-color: #FFB930;

        .text {
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #222222;
        }

      }

    }

    .card--footer {
      border-top: 1px solid #e8e8e8;
      padding: 12px 24px;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

  }

  .ant-radio-group {

    .ant-radio-wrapper {
      margin-right: 25px;
    }

  }

  .ant-card-head-title {
    font-size: 20px;

    .card--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .switch {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;

        button {
          margin-left: 10px;
        }

      }

    }

  }

  .ant-form-item-label {
    line-height: 1;
    margin-bottom: 5px;
  }

  .ant-form-item label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1D1D1D;
  }

  .ant-input-group-addon {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #222222;
  }

  .reward-name {

    .ant-input {
      height: 40px;
      max-width: 200px;

      &-group {
        width: auto;

        &-wrapper {
          width: auto;
          top: 0;
        }

      }

    }

  }

  .match {

    &--container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 24px;
      vertical-align: top;

      .ant-form-item {
        margin: 0;

        .ant-form-explain {
          display: none;
        }

      }

      .ant-form-item-label {
        line-height: 1;
        margin-bottom: 10px;

        label {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #1D1D1D;
        }

      }

      .ant-input {
        height: 40px;
        max-width: 140px;

        &-group {
          width: auto;

          &-wrapper {
            width: auto;
          }

        }

      }

      &.solo {

        .match--row {
          width: 100%;
        }

        .ant-form-item {
          margin: 0;
          width: 100%;

          .ant-form-explain {
            display: none;
          }

        }

        .ant-input {
          max-width: 200px;

          &-group {
            width: 100%;

            &-wrapper {
              width: 100%;
            }

          }

        }

      }

    }

    &--column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &:first-of-type {
        margin-right: 25px;
      }

    }

    &--row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      .seperator {
        display: block;
        margin: 0 7px;
        align-self: stretch;
        line-height: 39px;
        vertical-align: middle;
        font-weight: bold;
      }

    }

  }

  .alert {
    padding: 15px;
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    border-radius: 4px;
  }

}
