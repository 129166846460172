.page-dashboard {

  .title-module {
    margin-bottom: 25px;
  }

  .ant-card {
    position: relative;
    border-radius: 0;
    border: 1px solid #FFFFFF;
    overflow: hidden;
    transition: all .15s ease;
    margin-bottom: 12px;

    .ant-card-meta-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #757575;
      margin-bottom: 20px;
    }

    .ant-card-meta-description {
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 27px;
      color: #222222;
    }

    .checkmark {
      width: 50px;
      height: 50px;
      background: #00e2a7;
      position: absolute;
      left: -26px;
      top: -26px;
      transform: rotate(45deg) translateX(-100%);
      opacity: 0;
      transition: all .15s ease;

      i {
        color: #FFFFFF;
        font-size: 11px;
        transform-origin: 50% 50%;
        transform: rotate(-45deg) scale(0.3) translateZ(0);
        position: absolute;
        bottom: 19px;
        right: 1px;
        transition: transform 0.2s ease 0.15s;
      }

    }

    &:hover,
    &.active {
      border: 1px solid #00e2a7;
      box-shadow: 0 4px 12px rgba(0,226,167,.15), inset 0 0 0 1px #00e2a7;
      cursor: pointer;

      .checkmark {

        i {
          transform: rotate(-45deg) scale(1) translateZ(0);
        }

      }

    }

    &.active {

      .checkmark {
        opacity: 1;
        transform: rotate(45deg) translateX(0);
      }

    }

    &.dark {
      background-color: #222222;
      border: 1px solid #222222;

      &:hover {
        border: 1px solid #00e2a7;
        box-shadow: 0 4px 12px rgba(0,226,167,.15), inset 0 0 0 1px #00e2a7;
        cursor: pointer;
      }

      .circle {
        background-color: #00E2A7;
        display: inline-flex;
        font-size: 16px;
        color: #222222;
        height: 33px;
        width: 33px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
      }

      .ant-card-meta-title {
        margin-bottom: 18px;
      }

      .ant-card-meta-description {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
      }

    }

    /* Inline dropdowns */
    .ant-select-selection--single {
      position: relative;
      height: auto;
      cursor: pointer;
      border-radius: 0;
      border: none;
      box-shadow: none;
    }

    .ant-select-selection-selected-value {
      border-bottom: 1px dashed #222222;
      padding: 0;
    }

    .ant-select-selection__rendered {
        margin: 0;
        line-height: 20px;
        color: #1D1D1D;

        i {
          display: none;
        }
    }

    .ant-select-arrow {
      margin-left: 4px;
      right: auto;
      top: 62%;
      font-size: 9px;
      color: #1D1D1D;
    }

  }

  .search {

    input {
      background: #FFFFFF;
      border: 1px solid #DBE0E7;
      box-sizing: border-box;
      border-radius: 21px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #959595;
    }

    .ant-input {
      min-width: 325px;
    }

    .ant-btn {
      border: none;
      padding: 0;
      margin: 0 5px 0 0;
      line-height: 2.5;
    }

  }

  .antd-pro-charts-chart-card-meta {

    span {
      color: rgba(0,0,0,.45);
      font-size: 14px;
      line-height: 22px;
      height: 22px;
    }

  }

  .antd-pro-charts-chart-card-total {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    color: rgba(0,0,0,.85);
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 38px;
    height: 38px;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .ant-spin-blur::after {
    display: none;
  }

  .ant-table-placeholder {
    background: transparent;
    border: none;
  }

  .filter-badge {
    color: #FFFFFF;
    height: 40px;
    background: #222222;
    box-sizing: border-box;
    border-radius: 21px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    i {
      margin-right: 10px;
    }

  }

  .ant-divider-horizontal {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .agent {

    .ant-table-tbody > tr {

      &:hover {
        cursor: default;
      }

    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background-color: #FFF;
    }

  }

  .icon-caution {
    color: #FFB930;
  }

}
