.page.page-my-app {

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1D1D1D;
    border-bottom: 1px solid #DBE0E7;
    padding-bottom: 10px;
    margin-top: 35px;
    margin-bottom: 30px;
  }

  h4 {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #1D1D1D;
    margin-bottom: 0;
  }

  .ant-form-item label {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  .ant-menu {
    background-color: transparent;
    margin-bottom: 35px;
    margin-top: 35px;

    &-horizontal {
      border-bottom: 1px solid #DBE0E7;
    }

    &-horizontal > .ant-menu-item {
      padding: 0 35px;
      font-size: 16px;
    }

    &-horizontal > .ant-menu-item-selected {
      color: #222222;
      font-weight: 500;
      border-bottom: 2px solid #00E2A7;
    }

    &-horizontal > .ant-menu-item-selected > a {
      color: #222222;
    }

    &-horizontal > .ant-menu-item:hover {
      color: #222222;
      border-bottom: 2px solid #00E2A7;
    }

    &-horizontal > .ant-menu-item > a:hover {
      color: #222222;
    }

  }

}
