html,
body {
  font-family: 'Montserrat', Avenir,Helvetica Neue,Arial,Helvetica, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #222222;
}

h1, h2 {
  font-weight: normal;
}

h3, h4, h5, h6 {
  font-weight: 500;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 21px
}

h5 {
  font-size: 19px;
}

h6 {
  font-size: 17px;
}

.title-module {

  margin-bottom: 0;

  h1 {
    line-height: 30px;
    margin-bottom: 0;
  }

  p {
    margin-top: 18px;
    margin-bottom: 0;
  }

  &--row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .info {
      position: relative;
      top: 2px;
      background-color: #D4D8E0;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;

      i {
        font-size: 10px;
        color: #595959;
      }

    }

  }

  &--container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &.with-back {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    i {
      margin-right: 10px;
    }

    h1 {
      margin: 0;
    }

  }

}

.ant-tooltip-content {
  font-size: 12px;
}

.dashboard-tooltip {

  .ant-tooltip-inner {
    padding: 11px 13px;
    color: #DDDDDD;

    b {
      font-weight: 600;
      color: #FFFFFF;
    }

  }

}

.ant-select-selection {
  border-radius: 0;

  &:hover {
    border-color: #9EBBFF;
  }

}

.ant-select-dropdown {
  border-radius: 0;

  .ant-select-dropdown-menu-item {
    padding: 7px 35px 7px 15px;

    i {
      font-size: 12px;
      margin-right: 3px;
      position: relative;
      top: 1px;
      opacity: 0;
    }

  }

  .ant-select-dropdown-menu-item-selected {

    i {
      opacity: 1;
    }

  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #EDF2FF;
  }

}

.ant-select-open .ant-select-selection {
  border-color: #9EBBFF;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(82, 133, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(82, 133, 255, 0.2);
}
