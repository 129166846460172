.page-user-profile {

  h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  h3 {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 25px;
  }

  a {

    &:hover {

      h2 {
        text-decoration: underline;
      }

    }

  }


  .user-details {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--name {

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      h1 {
        font-size: 29px;
        color: #222222;
        margin: 0 15px 0 0;
      }

      .ant-rate {
        font-size: 18px;

        &-star {
          margin-right: 1px;
        }

      }

    }

    &--meta {

      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #222222;

      .divider {
        color: #B5B5B5;
      }

    }

    &--type {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 25px;
      color: #6B6B6B;

      i {
        margin-right: 4px;
      }

    }

  }

  .card {

    margin-bottom: 20px;
    border-radius: 0;

    &-details {

      list-style: none;
      margin: 0;
      padding: 0;

      &--row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        border-bottom: 1px solid #E9E9E9;
        padding: 17px 0 7px 0;
        list-style: none;

        &.hidden {
          display: none;
        }


      }

      &--left,
      &--right {

        span {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
        }

      }

      &--left,
      &--row {
        font-weight: 500;
      }

      &--left {
        font-weight: 500;
        color: #6B6B6B;

        span {
          justify-content: flex-start;
        }

      }

      &--right {
        font-weight: bold;
        color: #1D1D1D;
        position: relative;
        text-align: right;
        justify-content: flex-end;
      }

      &--action {
        display: block;
        text-align: center;
        width: 100%;
        padding: 15px 0 0 0;
        font-size: 13px;
        font-weight: bold;
      }

    }

    &-activity {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 35px 0;
      margin: 0;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &--title {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
        color: #1D1D1D;
        margin-bottom: 7px;

        &-large {
          font-size: 31px;
          line-height: 28px;
        }

        &-small {
          font-size: 16px;
          font-weight: 600;
          margin-left: 4px;
          line-height: 16px;
        }

      }

    }

    &--inner {
      display: flex;
      flex-direction: row;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

    }

    &--left {
      width: calc(100% / 3 * 2);

      @include media-breakpoint-down(md) {
        width: 100%;
      }

    }

    &--right {
      width: calc(100% / 3);

      @include media-breakpoint-down(md) {
        width: 100%;
      }

    }

    &.dark {

      background-color: #1C1C1C;
      overflow: visible;

      h2, h3, h4 {
        color: #FFFFFF;
      }

      h4 {
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        margin-top: 15px;

        .icon-pencil {
          font-size: 14px;
          position: relative;
          top: -2px;
          margin-left: -16px;
          opacity: 0;
          transition: opacity 0.15s ease;

          &:hover {
            cursor: pointer;
            opacity: 1;
          }

        }

      }

      p {
        color: #C5C5C5;
        font-size: 11px;
        max-width: 210px;
        margin-left: auto;
        margin-right: auto;

        b {
          color: #FFFFFF;
        }

      }

      .ant-card-body {
        padding: 0;
      }

      .card {

        &-details {

          &--row {
            border-bottom: 1px solid #383838;
          }

          &--left {
            flex: 1;
            color: #989898;
          }

          &--right {
            flex: 1;
            font-weight: bold;
            color: #FFFFFF;

            &.green {
              color: #00E2A7;
            }

            & > .icon-pencil {
              opacity: 0;
              transition: opacity 0.15s ease;
            }

            &:hover {

              & > .icon-pencil {
                opacity: 0.8;

                &:hover{
                  opacity: 1;
                  cursor: pointer;
                }

              }

            }

          }

          &--action {
            text-align: left;
          }

        }

        &--left,
        &--right {
          padding: 24px;
        }

        &--left {
          background-color: #222222;
        }

        &--right {
          align-self: stretch;
          flex: 1;

          h2 {
            text-align: center;
            margin-bottom: 15px;
          }

          &:hover {

            h4 {

              .icon-pencil {
                opacity: 0.8;

                &:hover {
                  cursor: pointer;
                  opacity: 1;
                }

              }

            }

          }

        }

        &--progress {
          max-width: 145px;
          margin: 25px auto 25px auto;
          position: relative;

          &-overlay {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          &-percent {
            color: #FFFFFF;
            font-size: 31px;
          }

          &-label {
            color: #808080;
            font-size: 13px;
          }

        }

        &--ownership {

          &-lockup {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 30px 0;
          }

          &-circle {
            display: block;
            background-color: blue;
            height: 50px;
            width: 50px;
            border-radius: 50%;
          }

          &-details {
            display: flex;
            flex-direction: column;
            margin-left: 15px;

            &-title {
              font-weight: 500;
              font-size: 13px;
              line-height: 25px;
              color: #989898;
            }

            &-value {
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              letter-spacing: -0.5px;
              color: #FFFFFF;
            }

          }

          &-divider {
            height: 1px;
            width: 100%;
            background-color: #343434;
          }

          &-progress {

            position: relative;

            &-overlay {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              position: absolute;
              color: #FFFFFF;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }

          }

        }

        &--full {

          padding: 50px 15px;

          img {
            width: 100%;
            max-width: 125px;
            display: block;
            margin: 0 auto 25px auto;
          }

          p {
            max-width: 100%;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #FFFFFF;
            text-align: center;
            margin-bottom: 0;
          }

        }

      }

    }

    .verified {
      height: 14px;
      width: 14px;
      display: block;
      margin-left: 8px;
    }

  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 125px 0;
    width: 100%;
  }

  .inline-editor {

    .icon-pencil {
      opacity: 0;
      transition: opacity 0.15s ease;
    }

    &:hover {

      .icon-pencil {
        opacity: 0.8;

        &:hover{
          opacity: 1;
          cursor: pointer;
        }

      }
    }

    &--focused {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 165px;

      input {
        text-align: right;
        background-color: transparent;
        color: #FFFFFF;
        font-weight: bold;
        background-image: none;
        border: 1px solid #326EFF;
      }

      .has-error {

        input {
          border-color: #f5222d;
          background-color: transparent;
        }

      }

      .ant-row {
        padding: 0;
        margin: 0;
      }

      .ant-form-explain {
        display: none;
      }

    }

  }

}

.mortgage {

  &-modal {

    .ant-input-number,
    .ant-calendar-picker {
      width: 100%;
    }

  }

}

.valuation {

  &-modal {

    &--row {
      width: calc(100% + 10px);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0 -5px;

      .ant-card {
        flex: 1;
        margin: 0 5px;
      }

    }

    p {
      text-align: left;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      margin: 0 0 20px 0;

      span {
        position: relative;
        top: -4px;
        left: 2px;
        font-size: 10px;
      }

    }

  }

  &-select {
    position: relative;
    border-radius: 0;
    border: 1px solid #DCDCDC;
    overflow: hidden;
    transition: all .15s ease;
    padding: 15px 10px 35px;
    box-shadow: none;

    .checkmark {
      width: 50px;
      height: 50px;
      background: #00e2a7;
      position: absolute;
      left: -26px;
      top: -26px;
      transform: rotate(45deg) translateX(-100%);
      opacity: 0;
      transition: all .15s ease;

      i {
        color: #FFFFFF;
        font-size: 11px;
        transform-origin: 50% 50%;
        transform: rotate(-45deg) scale(0.3) translateZ(0);
        position: absolute;
        bottom: 19px;
        right: 1px;
        transition: transform 0.2s ease 0.15s;
      }

    }

    .ant-card-body {
      padding: 0;
    }

    .ant-form {
      margin: 15px 0 0 0;
      padding: 0 15px;

      .ant-form-item-label {
        line-height: 1;

        label {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
        }

      }

    }

    .ant-form-item {
      margin: 10px 0 0 0;

      .ant-form-item-children {
        line-height: 1;
      }

      .ant-input-number,
      .ant-input-number-input,
      .ant-input {
        width: 100%;
        height: 39px;
      }

      .ant-form-explain {
        display: none;
      }

    }

    .ant-calendar-picker {
      width: 100%;
    }

    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 10px;

      span {
        position: relative;
        top: -4px;
        left: 2px;
        font-size: 10px;
      }

    }

    img {
      display: block;
      width: 100%;
      max-width: 62px;
      margin: 0 auto;
    }

    .avm-price {
      display: block;
      font-weight: 500;
      font-size: 28px;
      line-height: 19px;
      text-align: center;
      color: #222222;
      margin-top: 25px;
      margin-bottom: 15px;
    }

    &:hover,
    &.active {
      border: 1px solid #00e2a7;
      box-shadow: 0 4px 12px rgba(0,226,167,.15), inset 0 0 0 1px #00e2a7;
      cursor: pointer;

      .checkmark {

        i {
          transform: rotate(-45deg) scale(1) translateZ(0);
        }

      }

    }

    &.active {

      .checkmark {
        opacity: 1;
        transform: rotate(45deg) translateX(0);
      }

    }

  }

  &-button {
    text-align: right;
  }

}
