@import '_generic';
@import '_dashboard';
@import '_split';


.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #181818;
}

.ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #222;
    transition: all 0.2s;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: #222;
}

.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-item-selected > a {
    color: #00e2a7;

    .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-arrow::before {
        background-color: #00e2a7;
    }

    &:hover {
        color: #00e2a7;
    }

}

.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
    .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-arrow::before {
        background-color: #00e2a7;
    }
}

.ant-menu-dark .ant-menu-item-selected > a {

    color: #00e2a7;

    &:hover {
        color: #00e2a7;
        cursor: default;
    }

}

.ant-menu-dark .ant-menu-submenu-selected .ant-menu-item-selected {

    background-color: transparent;

    & > a {
        color: #FFF;
        font-weight: 600;
    }

}

.ant-btn-primary {
    color: #fff;
    background-color: #222;
    border-color: #222;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-primary:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.page-settings .share-url--container:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 110%;
    top: -25px;
    left: -15px;
    background-color: transparent !important;
}

.dashboard-layout .header .trigger:hover {
    color: #5285FF;
}

a {
    color: #5285FF;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}

.ant-pagination-item-active a {
    color: #5285FF;
}

.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: #5285FF;
}

html {
    --antd-wave-shadow-color: #5285FF;
}

.ant-input:focus {
    border-color: #5285FF;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(82, 133, 255, 0.2);
}

.ant-btn-primary:focus {
    color: #fff;
    background-color: #222;
    border-color: #222;
}

.ant-menu-item-selected > a {
    color: #5285FF;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #00e2a7;
}

ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline li.ant-menu-item-selected {
    background-color: #ecfffa;
}

ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline li.ant-menu-item-selected > a {
    color: #00e2a7;
    font-weight: bold;
}

.ant-menu-item > a:hover {
    color: #00e2a7;
}

.ant-input:hover {
    border-color: #5285FF;
    border-right-width: 1px !important;
}

.login .login--button {
    width: 100%;
    margin-top: 20px;
    background: #000;
    border: 1px solid #000;
}

.login .login--forgot {
    float: right;
    color: #00e2a7;
}

a:hover {
    color: #295ede;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #5285fd;
}

.ant-radio-inner::after {
    background-color: #5285fd;
}

.default-error {
    text-align: center;
    padding: 50px 0;

    h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #222222;
        margin-bottom: 15px;
    }

    p {
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        text-align: center;
        color: #3E3E3E;
        max-width: 300px;
        margin: 0 auto 30px auto;
    }

    .button.primary {
        display: inline-flex;
        width: auto;
        align-items: center;
        justify-content: center;
        padding: 10px 50px;
    }

}

.dashboard-error {
    padding: 100px 0;
}
