.pulse {

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;

  &--dot {

    position: relative;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #FFB930;
    border: 0;
    outline: 0;
    opacity: 1;
    transition: opacity 0.3s ease, background 0.15s ease;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: #FFB930;
      border-radius: 50%;
      z-index: 1;
      animation: pulseAnimation 2s infinite;
      transition: all 5s ease;
    }

  }

  &--text {
    font-size: 0.6rem;
    color: #222222;
    font-weight: 600;
    margin-left: 0.5rem;
  }

  @keyframes pulseAnimation {
    from {
      opacity: 0.5;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(2.5);
    }
  }

}
