.page-partners {

  .module-heading {

    .module-title {
      margin-bottom: 5px;
    }

    .module-text {
       margin-bottom: 15px;
    }

  }

  .item-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0 0 10px 0;
      height: 54px;
      width: 100%;
      background-color: #FFFFFF;
      border-radius: 30px;
      border: 1px solid #DBE0E7;
      padding: 7px 9px 7px 15px;

      .email {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        font-weight: 600;

        span {
          font-weight: normal;
        }

        i {
          font-size: 20px;
          margin-right: 10px;
        }

      }

      a {
        font-size: 13px;
        padding: 0 15px;
      }

      button {
        border-radius: 30px;
        background-color: #222222;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 17px;
        height: 34px;
        min-width: 85px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

    }

  }

  .request-module {
    margin: 0;

    li {

      i {
        color: #00E2A7;
      }

      a {
        color: #525252;
      }

      border: 2px solid #00E2A7;
      background-color: #E8FFF9;
    }

  }

  .linked-module {
    margin: 25px 0 0;

    li {

      i {
        color: #00E2A7;
      }

    }

    p {
      margin-top: 15px;
    }

  }

  .invite-module {

    margin: 0 0 25px 0;

    .ant-form-item {
      margin: 0;
    }

    .ant-form-item-control.has-error {

      i {
        color: #f5222d;
      }

      input::placeholder {
        color: #f5222d;
      }

    }

    .ant-form-item-children {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      input {
        width: 100%;
        max-width: 525px;
        height: 40px;
      }

      i {
        margin-left: 10px;
      }

    }

    .ant-form-explain {
      display: none;
    }

    a {
      margin-bottom: 25px;
      width: 100%;
      max-width: 525px;
      border: 1px dashed #5285FF;
      color: #5285FF;
      border-radius: 4px;
      padding: 4px 11px;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

  }

  .sent-module {
    margin: 25px 0;
  }

}
