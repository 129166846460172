.page-lead-routing {

  .ant-spin-blur {
    overflow: visible;
    opacity: 1;
  }

  .item-list {
    list-style: none;
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: opacity .3s ease;

    li {
      display: flex;
      flex-direction: row;
      list-style: none;
      margin: 0 0 10px 0;
      justify-content: center;
      align-items: center;

      .handle {
        margin-right: 15px;
        font-size: 16px;
        color: #DBE0E7;

        &:hover {
          cursor: grab;
        }

        &:active {
          cursor: grabbing;
        }

      }

      & > div {

        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 58px;
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 30px;
        border: 1px solid #DBE0E7;
        padding: 7px 25px 7px 10px;
        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }

        .avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
          width: 36px;
          border-radius: 50%;
          background-color: #222222;
          color: #FFFFFF;
          margin-right: 15px;
          overflow: hidden;

          span {
            position: relative;
            top: 1px;
          }

          img {
            display: block;
            width: 100%;
          }

          &--container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
          }

        }

        a {
          font-size: 13px;
          padding: 0 15px;
        }

        .badge {
          padding: 0 10px;
          font-weight: 700;
          font-size: 8px;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: .28px;
          border-radius: 13px;
          color: #fff;
          display: none;
          position: absolute;
          transform: translateY(-50%);
          top: 0;
          left: 60px;
        }

      }

      &.previous {

        & > div {
          border-color: #2b91fe;
          border-width: 2px;

          .badge.previous {
            display: block;
            background-color: #2b91fe;
          }

        }

      }

      &.next {

        & > div {
          border-color: #00e2a7;
          border-width: 2px;

          .badge.next {
            display: block;
            background-color: #00e2a7;
          }

        }

      }

      &.sortable-ghost {

        & > div {
          background-color: #DBE0E7;
          border-style: dashed;
          opacity: 0.6;
        }

      }

    }

  }

  .ant-spin-blur {

    &:after {
      opacity: 0;
    }

    .item-list {
      opacity: 0.3;
    }

  }

  .button {
    margin: 15px 0;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 61px;

    &--save {
      margin-right: auto;
    }

    &--distribute {
      margin-left: auto;
      padding: 20px 0;
    }

  }

  &--modal {

    .ant-skeleton {
      margin-bottom: 15px;
    }

    .distributions {

      border: 1px solid #E2E2E2;
      margin-top: 10px;
      margin-bottom: 20px;

      &--title {
        padding: 5px 10px;
        font-weight: 600;
        background-color: #f0f2f5;
      }

      &--row {
        border-top: 1px solid #E2E2E2;
        display: flex;
        flex-wrap: nowrap;

        &.header {
          font-weight: 600;
        }

        .distributions--column:first-of-type {
          border-right: 1px solid #E2E2E2;
        }

        .distributions--column:last-of-type {
          text-align: right;
        }

      }

      &--column {
        flex: 1 1 0;
        padding: 5px 10px;
      }

    }

  }

}