.page-oauth {

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -.5px;
    margin-bottom: 25px;
  }

  .logo {
    width: 75px;
    height: 75px;
    margin: 0 auto 30px auto;
    display: block;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #222222;
  }

  p {
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 25px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .list-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  ul {

    list-style: none;
    margin: 0 auto 35px auto;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    li {
      font-size: 13px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      list-style: none;
      margin: 0 0 5px 0;
      padding: 0;
      display: inline;

      i {
        margin-right: 5px;
        color: #00e2a7;
      }

    }


  }

  .logout {
    text-align: center;
    padding: 25px 0;
  }

}
