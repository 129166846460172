.csv-module {

  .field-title,
  .field-type {
    display: block;
  }

  .field-title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    font-size: 13px;
  }

  .field-type {
    font-size: 12px;
  }

  .steps-card {
    padding: 16px 24px;
    margin-bottom: 20px;
  }

  .ant-form-item label {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    span {
      color: #9E9E9E;
    }

  }

  .ant-input-number,
  .ant-calendar-picker,
  .ant-select
  {
    width: 100%;
  }

  .ant-steps-item-icon {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
  }

  .ant-steps-item-icon {
    margin-right: 0;
  }

  .ant-steps-item-title {
    font-size: 14px;
    line-height: 26px;
    margin-left: 8px;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #00e2a7;
    border-color: #00e2a7;
  }

  .ant-steps-item-finish {

    & .ant-steps-item-icon {
      background-color: #00e2a7;
      border-color: #00e2a7;
      color: #FFFFFF;

      & > .ant-steps-icon {
        color: #FFFFFF;
      }

    }

  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #00e2a7;
    top: 14px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    top: 14px;
  }

  .ant-card-body {
    padding: 0;
  }

  .card {

    &-content {
      margin-bottom: 25px;

      h4 {
        font-weight: 500;
        font-size: 17px;
        line-height: 17px;
        color: #1D1D1D;
        padding-bottom: 10px;
        margin-top: 5px;
        margin-bottom: 15px;
      }

    }

    &--inner {
      padding: 24px;
    }

    &--checkbox {
      padding: 1.5rem 0 0 0;
      text-align: right;
    }

    &--footer {
      border-top: 1px solid #e8e8e8;
      padding: 12px 24px;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      &-left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        button {
          margin-right: 10px;
        }

      }

      &-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

    }

  }

  .ant-table {
    border: 1px solid #e8e8e8;
    border-bottom: 0;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 10px 16px;
    white-space: nowrap;
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }

  .ant-divider-horizontal {
    margin-top: 15px;
    margin-bottom: 35px;
    background-color: transparent;
    border-bottom: 1px dashed #E8E8E8;
  }

  .summary {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 10px;

    &--item {
      flex: 1;
      text-align: center;
      padding: 15px;
      border: 1px solid #E8E8E8;
      background-color: transparent;
      transition: background-color 0.3s ease;
      box-shadow:inset 0 0 0 1px transparent;

      &-title {

        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 5px;

        &.green {
          color: #00E2A7;
        }

        &.orange {
          color: #FFB443;
        }

        &.red {
          color: #F55F5F;
        }

      }

      &-subtitle {
        color: #444444;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
      }

      &:nth-of-type(2) {
        margin-left: -1px;
        margin-right: -1px;
      }

    }

  }

  .error-red {
    color: #F55F5F;
  }

  .modal-loading {
    text-align: center;

    img {
      display: block;
      margin: 25px auto;
      width: 100%;
      max-width: 150px;
    }

  }

  &-error-table {

    .ant-collapse-header {
      text-align: left;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .ant-table-small {
      border: none;
      border-radius: 0;
    }

    .ant-table-row {
      font-size: 11px;
    }

  }

  /* Step 0 - Audience Select */

  .audience {

    &-select {
      position: relative;
      border-radius: 0;
      border: 1px solid #DCDCDC;
      overflow: hidden;
      transition: all .15s ease;
      padding: 45px 25px;
      box-shadow: none;

      .checkmark {
        width: 50px;
        height: 50px;
        background: #00e2a7;
        position: absolute;
        left: -26px;
        top: -26px;
        transform: rotate(45deg) translateX(-100%);
        opacity: 0;
        transition: all .15s ease;

        i {
          color: #FFFFFF;
          font-size: 11px;
          transform-origin: 50% 50%;
          transform: rotate(-45deg) scale(0.3) translateZ(0);
          position: absolute;
          bottom: 19px;
          right: 1px;
          transition: transform 0.2s ease 0.15s;
        }

      }

      .icon-csv,
      .icon-single,
      .icon-financials-circle,
      .icon-digs-home {
        display: block;
        font-size: 38px;
        color: #222222;
        margin: 0 auto 15px auto;
        text-align: center;
      }

      h3 {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
      }

      p {
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        margin: 0;
      }

      &:hover,
      &.active {
        border: 1px solid #00e2a7;
        box-shadow: 0 4px 12px rgba(0,226,167,.15), inset 0 0 0 1px #00e2a7;
        cursor: pointer;

        .checkmark {

          i {
            transform: rotate(-45deg) scale(1) translateZ(0);
          }

        }

      }

      &.active {

        .checkmark {
          opacity: 1;
          transform: rotate(45deg) translateX(0);
        }

      }

    }

    &-button {
      text-align: right;
    }

  }

  /* Step 1 - Upload CSV */

  .requirements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    ul {
      width: 25%;
      padding-left: 18px;

      li {
        margin-bottom: 5px;
      }

    }

  }

  /* Step 2 - Table */

  .module {

    &--table {

      display: flex;
      flex-direction: column;
      border: 1px solid #e8e8e8;

      &-row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e8e8e8;

        &:last-of-type {
          border-bottom: none;
        }

      }

      &-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding: 10px 15px;
        flex: 1 1 0;
      }

      &-header {
        background-color: #fafafa;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-align: left;
        font-size: 15px;
      }

      .toggle {
        width: 100%;
        text-align: center;
        font-weight: normal;
        font-size: 12px;
        padding: 5px;
        color: #5285FF;

        &:hover {
          color: #295ede;
          text-decoration: underline;
          cursor: pointer
        }

      }

    }

  }

  .review {

    padding: 24px;
    border: 1px dashed #E8E8E8;
    overflow: hidden;

    &-label {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 3px;

      span {
        color: #9E9E9E;
      }

    }

    &-value {
      margin-bottom: 15px;
    }

  }

  .custom-errors-component {
    margin-bottom: 15px;
  }

}

