.fadeInUp {
  animation-name: digsFadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  display: block;
}

.fadeIn {
  animation-name: digsFadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes digsFadeIn{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes digsFadeInUp{
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
