.page-onboarding-headshot {

  text-align: center;
  max-width: 410px;
  margin: 0 auto;

  .dzu-dropzone {
    background: transparent;
    border: none;
    border-radius: 0;
  }

  .dzu-dropzoneActive {

    .dzu-inputLabel {
      background-color: #E2E2E2;
    }

  }

  .dzu-inputLabel {
    background: #E9EBEE;
    border: 2px dashed #C2C7CE;
    border-radius: 8px;
    padding: 25px 0;
    width: 100%;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .dzu-inputLabelWithFiles {
    display: none;
  }

  .ReactCrop {

    .ord-nw,
    .ord-ne,
    .ord-sw,
    .ord-se {
      margin: 0;
    }

  }

  .ReactCrop__image {
    max-height: 100%;
  }

  .upload-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      letter-spacing: -0.5px;
      color: #222222;
      margin-bottom: 10px;
    }

    .button.primary {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #FFFFFF;
      margin: 0;
    }

    .disclaimer {
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 14px;

      &.error {
        color: #E93939;
      }

    }

  }

  .actions {
    padding: 25px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .avatar-preview {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &--actions {
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .button.primary {
        margin-bottom: 20px;
      }

    }

  }

}
