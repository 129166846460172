.page-payment {

  h1 {
    text-align: center;
  }

  .payment-card {

    width: 100%;
    margin: 0 auto;
    padding:40px 24px 20px 24px;
    border: 1px solid #EFEFEF;
    background: #fff;
    margin-bottom: 25px;

    .ant-card-body {
      padding: 0;
    }

    .ant-form-item-label {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.5px;
      margin-bottom: 5px;
      color: #222222;
    }

    .button.primary {
      width: 100%;
    }

    .ant-input {
      height: 40px;
    }

    .ant-select-selection--single {
      height: 40px;
    }

    .ant-select-selection__rendered {
      line-height: 40px;
    }

    .ant-input-group-addon {
      padding: 0 15px;
    }

  }

  .payment-form {

    .ant-form-item-children {

      position: relative;
      height: 40px;
      display: block;

    }

    .cc-number {
      padding-left: 45px !important;
    }

    .invoice {
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      background-color: #FAFAFA;
      color: #222222;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      margin-top: 10px;
      margin-bottom: 29px;

      &--top,
      &--bottom {
        padding: 15px 25px;
      }

      &--top {
        border-bottom: 1px dashed #d9d9d9;



      }

      &--bottom {

        .total {

          &.w-discount {
            margin-top: 10px;
          }

          font-weight: bold;

          span {
            font-size: 22px;
          }

        }

      }

      .item {

        span {
          font-weight: bold;
        }

      }

    }

    .ant-input-group-addon {
      padding: 0;
    }

    .ant-input-search-button {
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
    }

    .ant-input.StripeElement {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & > div {
        width: 100%;
      }

    }

    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 45px;
    }

  }

  ul {

    margin: 0 auto 15px auto;
    padding: 0;
    list-style: none;

    li {
      font-weight: 500;
      font-size: 13px;
      line-height: 30px;
      padding-left: 14px;

      &:before {
        font-size: 12px;
        line-height: 30px;
        color: #00E2A7;
        font-family: 'icomoon';
        content: "\e907"; /* FontAwesome Unicode */
        display: inline-block;
        margin-right: 14px; /* same as padding-left set on li */
      }

    }

  }

}
