.generic-layout {

  min-height: 100vh;
  background-color: #fff;

  .ant-card {
    padding: 0;
    border-radius: 0;
    border: 1px solid #EDECED;
  }

  .ant-card-body {
    padding: 30px;
  }

  .button.primary {
    font-size: 14px;
    padding: 15px 20px;
    height: auto;
    width: 100%;
    margin-top: 20px;
  }

  &--content {
    padding: 25px 0 24px;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }

  &--logo {
    width: 155px;
    margin: 25px auto 50px;
    display: block;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
    }

  }

  &--footer {
    display: block;
    margin: 48px 0 24px 0;
    padding: 0 16px;
    text-align: center;
    color: hsla(0, 0%, 100%, .45);
    font-size: 14px;
  }

  .ant-checkbox-wrapper {
    color: #fff;
  }

  .custom-errors-component {
    margin-bottom: 20px;
  }

}
