.feed-item {

  background-color: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  outline: 2px solid transparent;
  transition: border-color 0.3s ease;

  &.open {

    &.changed {
      outline-color: #00e2a7;
      box-shadow: 0px 6px 15px rgba(0, 226, 167, 0.15);
    }

    &.locked {
      outline-color: #FFB930;
      box-shadow: 0px 6px 15px rgba(255, 185, 48, 0.15);
    }

  }

  &__heading {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--handle {
      margin-right: 15px;
      font-size: 16px;
      color: #DBE0E7;

      &:hover {
        cursor: move;
      }

    }

    &--left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - 65px);
    }

    &--right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        outline: none;
        border: none;
        background-color: transparent;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

      }

    }

    &--actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      opacity: 1;
      transition: opacity 0.3s ease;

      i {
        font-size: 16px;
        margin-right: 5px;
      }

      &.black {
        color: #000000;
      }

      &.red {
        color: #E93939;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }

    }

  }

  &__status {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    height: auto;
    max-height: 0;
    transition: max-height 0.3s ease-in;

    &--inner {
      width: 100%;
      min-height: 53px;
      background-color: #00E2A7;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-bottom: 1px solid #e8e8e8;

      .text {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #222222;
      }

      .update {
        margin-left: 10px;
      }

      .ant-btn-default {
        background-color: transparent;
        border-color: rgba(34,34,34, 0.2);
      }

    }

    &.active {
      max-height: 75px;
    }

    &.locked {
      max-height: 75px;

      .feed-item__status--inner {
        background-color: #FFB930;
      }

    }

  }

  &--title {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      color: #888888;
    }

  }

  &__inner {
    border-top: 1px solid #e8e8e8;
  }

  &__body {
    padding: 20px;
  }

  &__buttons {
    padding: 0 20px 30px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--container {
      display: flex;
      flex-direction: row;
      align-items: center;
      opacity: 1;
      transition: opacity 0.3s ease;

      p {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #8B8B8B;
        max-width: 215px;
        margin: 0 0 0 10px;
      }

      &.disabled {
        opacity: 0;
        pointer-events: none;
      }

    }

    .update {
      margin-left: 10px;
    }

  }

  &__video {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;

    &--container {
      background-color: #F3F3F3;
      padding: 10px;
      width: 100%;
      overflow: hidden;
    }

    iframe{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &--actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 10px;

      .generate-thumbnail {
        display: block;
        line-height: 12px;
        font-size: 12px;
        color: #5285FF;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

      }

    }

  }

  &__thumbnail {

    span {
      display: flex;
    }

    img {
      display: block;
      width: 100%;
    }

    &--container {
      background-color: #F3F3F3;
      padding: 10px;
      width: 100%;
      max-width: 300px;

      &.bordered {
        border: 1px dashed #d9d9d9;
        transition: border-color 0.3s ease;
        padding: 0;

        .upload-button {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          color: #222222;
          font-size: 14px;
          padding: 10px;

          i {
            font-size: 16px;
            color: #222222;
            margin-right: 5px;
          }

        }

        &:hover {
          cursor: pointer;
          border-color: #5285FF;
        }

      }

    }

    &--actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      span {
        display: block;
        line-height: 12px;
      }

      a, .replace-image {
        font-size: 12px;
        line-height: 12px;
        color: #5285FF;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

      }

    }

  }

  .ant-upload {
    width: 100%;
  }

}
