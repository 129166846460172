.generic-inactive {
  border: none;
  border-radius: 0;

  &--inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    & > div {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      justify-content: center;

      &:first-of-type {
        padding: 30px 50px;
        border-right: 1px solid #EEEEEE;
      }

    }

    p {
      margin: 0;
    }

    ul {
      margin: 15px 0 25px 0!important;
    }

    img {
      display: block;
      width: 100%;
    }

  }

  .ant-card-body {
    padding: 0;
  }

}
