.page-integrations {

  .module {

    &--header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 25px 0px;
    }

    &--table {

      display: flex;
      flex-direction: column;

      &-row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e8e8e8;

        &:last-of-type {
          border-bottom: none;
        }

      }

      &-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding: 16px 16px;
      }

      &-header {
        background-color: #fafafa;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-align: left;
        font-size: 17px;
        border-top: 1px solid #e8e8e8;
      }

    }

    &--buttons {
      padding: 16px 24px;
      border-top: 1px solid #e8e8e8;

      button {
        margin-right: 10px;
      }

      &.split {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          margin-right: 0;
        }

      }

    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      padding: 0px 16px;
    }

  }

  .ant-card-head-title {
    font-size: 20px;

    .card--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .switch {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;

        button {
          margin-left: 10px;
        }

      }

    }

  }

  .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-collapse-item.ant-collapse-item-disabled.ant-collapse-no-arrow > .ant-collapse-header {
    padding-right: 16px;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #e8e8e8;
  }

  .ant-collapse-header {
    padding-left: 0;
  }

  .title-module {
    justify-content: space-between;
    width: 100%;

    &--switch {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      .ant-switch {
        margin-right: 15px;
      }

      .details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .status {
        font-weight: 600;
        font-size: 12px;
        color: #222222;
      }

      .sync {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: #7E7E7E;
      }

    }

  }

}
