.dashboard-layout {
  .header {
    background: transparent;
    padding: 0;
    box-shadow: none;
    position: fixed;
    z-index: 2;
    transition: all 0.2s;

    .trigger {
      padding: 22px 24px;
      cursor: pointer;
      transition: color 0.3s;
      font-size: 20px;
      height: 64px;
      margin-left: -24px;
    }

    .trigger:hover {
      color: #5285ff;
    }

    .right {
      float: right;
      height: 100%;
      overflow: hidden;

      .action {
        cursor: pointer;
        padding: 0 0 0 25px;
        display: inline-block;
        transition: all 0.3s;
        height: 100%;

        > i {
          vertical-align: middle;
        }
      }

      .account {
        .avatar {
          height: 30px;
          width: 30px;
          line-height: 30px;
          margin: 0 0 0 8px;
          vertical-align: middle;
        }
      }

      & > span {
        padding: 0 25px;
      }

      @include media-breakpoint-down(sm) {
        .upgrade-button {
          display: none;
        }
      }

    }

    .menu {
      .anticon {
        margin-right: 8px;
      }
      .ant-dropdown-menu-item {
        width: 160px;
      }
    }

    &--inner {
      padding: 0 60px;

      @include media-breakpoint-down(md) {
        padding: 0 40px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 24px;
      }
    }

    &.active {
      background: #ffffff;
      box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    }

    .button {
      display: inline-block;
      font-size: 13px;
      padding: 7px 25px;
    }
  }

  .sidebar {
    padding: 0;
    height: 100vh;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    position: relative;
    z-index: 10;

    .logo {
      display: block;
      height: 64px;
      position: relative;
      line-height: 64px;
      padding-left: 35px;
      transition: all 0.3s;
      background-color: #222;
      overflow: hidden;

      .digspro-icon {
        margin-right: 5px;
      }

      .digspro-text {
        position: relative;
        top: 3px;
      }
    }

    .ant-menu-item {
      a {
        padding-left: 17px;
        transition: all 0.3s;
      }
    }

    .ant-btn-primary {
      background-color: #00e2a7;
      text-align: center;
      height: 40px;
      cursor: pointer;
      margin: 0 41px;

      &:hover {
        background-color: darken(#00e2a7, 5%);
      }
      
      span,
      i {
        color: #222;
        vertical-align: -webkit-baseline-middle;
      }

      span {
        margin: 0 41px;
      }

      @include media-breakpoint-down(md) {
        margin: 0 17px;
        span {
          display: none;
        }
      }
    }

    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: #00e2a7;
    }

    .ant-menu-submenu-title {
      & > span {
        padding-left: 17px;
        transition: all 0.3s;
      }
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item {
      height: 30px;
      line-height: 30px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &.ant-layout-sider-collapsed {
      .logo {
        padding-left: 26px;
      }

      .ant-menu-item {
        a {
          padding-left: 0;
        }
      }

      .ant-menu-submenu-title {
        & > span {
          padding-left: 0;
        }
      }

      .ant-btn-primary {
        margin: 0 17px;
        span {
          display: none;
        }
      }
    }
  }

  .ant-layout-content {
    margin: 24px 60px 0px;
    padding-top: 64px;
    padding-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin: 24px 40px 0px;
    }

    @include media-breakpoint-down(sm) {
      margin: 24px 24px 0px;
    }
  }

  .ant-page-header {
    background: #fff;
    padding: 16px 32px 0;
    border-bottom: 1px solid #e8e8e8;

    span {
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 16px;
    }
  }

  & > .ant-layout {
    transition: all 0.2s;
  }

  .column-max-width {
    max-width: 775px;
  }

  .ant-divider-horizontal {
    margin-top: 35px;
    margin-bottom: 35px;
    margin-left: -60px;
    width: calc(100% + 120px);
    background-color: #dbe0e7;
  }

  .ant-card {
    border: none;
    border-radius: 0;
  }

  .page {
    animation: fadeIn 0.5s;
  }

  .import-button {
    margin-top: 35px;

    a {
      color: #222222;
      border-radius: 4px;
      background-color: #00e2a7;
      padding-right: 17px;
      text-align: center;
      margin-right: 8px;
      overflow: hidden;
      opacity: 1;

      &:hover {
        color: #222222;
        opacity: 0.8;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
