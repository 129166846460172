.edit-provider-profile {

  .upload-block {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;

    &--images {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    &--label {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 15px;
    }

  }

  .app-preview {
    width: 100%;
    padding: 25px;
    display: flex;
    justify-content: center;

    &__container {
      flex-shrink: 0;
      position: relative;
      display: block;
      width: 425px;
      height: auto;
    }

    &__shell {

      &--underlay {

        img {
          display: block;
          width: 100%;
        }

      }

      &--overlay {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        img {
          display: block;
          width: 100%;
        }
      }

    }

    &__screen {
      position: absolute;
      top: 6%;
      bottom: 6%;
      left: 18.1%;
      right: 18.1%;
      border-radius: 25px;
      overflow: hidden;
      background-color: #0CA16D;
      display: flex;
      flex-direction: column;
      font-size: 100%;

      &--top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        margin-top: 25px;

        &-bar {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 7px 15px 7px 15px;
          margin-bottom: 20px;
          width: 100%;

          .arrow {
            color: #FFFFFF;

            &.empty {
              opacity: 0;
            }
          }

          .heading {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            color: #FFFFFF;
          }

        }

        &-contact {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &-avatar {
          overflow: hidden;
          border-radius: 50%;
          width: 71px;
          height: 71px;

          img {
            display: block;
            width: 100%;
          }

        }

        &-name {
          font-weight: 600;
          font-size: 11px;
          text-align: center;
          color: #FFFFFF;
          padding: 10px 0 0 0;
        }

        &-buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 0 23px 0;
        }

        &-button {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 1px solid rgba(255,255,255, 0.4);
          font-size: 10px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 4px;
        }

      }

      &--bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #FFFFFF;
        padding: 15px 15px;
        border-bottom: 1px solid #E9E9E9;
        flex-grow: 0;

        img.logo {
          display: block;
          max-width: 115px;
          max-height: 50px;
          width: auto;
          height: auto;
        }

        .location {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;

          .title {
            font-weight: bold;
            font-size: 9.5px;
            text-align: right;
            color: #1D1D1D;
          }

          .label {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 6px;
            text-align: right;
            letter-spacing: 0.13em;
            color: #808080;
          }

        }

      }

      &--content {
        background-color: #FCFCFC;
        flex-grow: 1;
        overflow: hidden;

        &-inner {
          padding: 15px 15px 100px 15px;
          width: 100%;
          height: 100%;
          overflow: scroll;

          .title {
            color: #1D1D1D;
            font-weight: 600;
            font-size: 11px;
            margin-bottom: 5px;
          }

          .text {
            font-style: normal;
            font-weight: 500;
            font-size: 9.5px;
            line-height: 16px;
            color: #676767;
          }

        }

      }

      &--bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FCFCFC;
        display: flex;
        justify-content: center;
        align-items: center;

        &-buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          padding: 15px 0 20px;
        }

        &-button {
          font-size: 18px;
          color: #222222;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 25px;

          .icon-digs-home {
            font-size: 20px;
          }

          .icon-financials {
            font-size: 20px;
            color: #B4B4B4;
          }

          .icon-icon-hamburger {
            font-size: 15px;
            color: #B4B4B4;
          }

        }

      }

    }

  }

  .sublabel {
    margin: 8px 0 0 0;
    padding: 0;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #919191;
  }

}
