.page-upgrade {

  .title-module {
      text-align: center;

      p {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
      }

  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #222222;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    line-height: 23px;
    color: #525252;
  }

  .card-list {

    max-width: 1000px;
    margin: 0 auto;

    .ant-row-flex {

      align-items: stretch;

      .ant-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 30px;
        padding-bottom: 30px;

        &-head-title {
          display: inline-block;
          flex: 1 1;
          padding: 13px 0;
        }

        &-body {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        a {
          margin-top: auto;
        }

      }

    }

    .ant-col {
      margin-bottom: 20px;
    }

    &--actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 25px 0;
    }

    &.inactive {
      opacity: 0.5;
      pointer-events: none;
    }

  }

  .pricing {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto 35px auto;

    &--plan {
      font-weight: bold;
      font-size: 21px;
      text-align: center;
      color: #222222;
      margin: 0;
    }

    &--amount {
      font-size: 40px;
      text-align: center;
      font-weight: 200;
      color: #222222;
      margin: 0;
    }

    &--subtitle {
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      color: #7E7E7E;
      margin: 0;
    }

  }

  ul {

    margin: 0 auto 50px auto;
    padding: 0;
    list-style: none;

    li {
      font-weight: 500;
      font-size: 13px;
      line-height: 30px;
      padding-left: 14px;

      &:before {
        font-size: 12px;
        line-height: 30px;
        color: #00E2A7;
        font-family: 'icomoon';
        content: "\e907"; /* FontAwesome Unicode */
        display: inline-block;
        margin-right: 14px; /* same as padding-left set on li */
      }

    }

  }

  .button {

    text-align: center;
    width: auto;
    margin: 0 auto;

    &:hover {
      cursor: pointer !important;
    }

    &.social {
      margin-top: auto;
      border: 1px solid #DBDBDB;

      &:hover {
        cursor: default !important;
        background-color: transparent;
      }

    }

  }

}
