.mini-radial-progress {
  transform: rotate(-90deg);
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;

  &--container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    max-width: 60px;
  }

  &--meter,
  &--value {
    fill: none;
  }

  &--meter {
    stroke: #373737;
  }

  &--value {
    stroke: #00e2a7;
  }

  &.inverted {

    .mini-radial-progress {

      &--meter {
        stroke: #326EFF;
      }

      &--value {
        stroke: #373737;
      }

    }

  }

}
