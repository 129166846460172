.page-onboarding-prospects {

  .integrations-list--container {
    max-width: 1000px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

}
