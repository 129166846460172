.button {

  &.primary {
    color: #fff;
    background-color: #222;
    border-color: #222;
    text-shadow: none;
    box-shadow: none;
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    width: auto;
    padding: 15px 70px;
    height: auto;
    border-radius: 4px;
    display: block;

    &.fill {
      width: 100%;
    }

    &:hover {
      background-color: rgba(#222, 0.92);
    }

  }

  &.social {
    background-color: #fff;
    color: #222;
    width: 100%;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    padding: 15px 70px;
    height: auto;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;


    &:hover {
      background-color: #f5f5f5;
    }

    &.ant-btn[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }

    img {
      margin-right: 10px;
    }

  }

}

.ant-btn-background-ghost.ant-btn-primary {
  color: #222222;
  background-color: transparent;
  border-color: #D4D8E0;
  text-shadow: none;

  &:hover,
  &:focus
  {
    color: #222222;
    background-color: transparent;
    border-color: #222222;
  }

}

.ant-btn:hover, .ant-btn:focus {
  color: #222222;
  background-color: #fff;
  border-color: #222222;
}

.ant-switch-checked {
  background-color: #00E2A7;
}
