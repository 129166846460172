.media-uploader {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  &--actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;

    &.apart {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

  }

  .dzu-dropzone {
    background: transparent;
    border-radius: 0;
    border: none;
    padding-left: 10px;
    padding-right: 20px;
    overflow: hidden;
  }

  .dzu-dropzoneActive {

    .dzu-inputLabel {
      background-color: #E2E2E2;
    }

  }

  .dzu-input {
    display: none !important;
  }

  .dzu-inputLabel {
    font-family: 'Montserrat', Avenir,Helvetica Neue,Arial,Helvetica, sans-serif;
    background: #E9EBEE;
    border: 2px dashed #C2C7CE;
    width: 100%;
    height: 125px;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .dzu-inputLabelWithFiles {
    display: none;
  }

  .ReactCrop {

    .ord-nw,
    .ord-ne,
    .ord-sw,
    .ord-se {
      margin: 0;
    }

  }

  .ReactCrop__image {
    max-height: 100%;
  }

  .upload-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > span {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      letter-spacing: -0.5px;
      color: #222222;
    }

    .disclaimer {
      margin-top: 8px;
      margin-bottom: 0;
      font-size: 14px;

      &.error {
        color: #E93939;
      }

    }

  }

  .avatar-preview {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    width: 125px;
    height: 125px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);

    img {
      display: block;
      width: 125px;
      height: 125px;
    }

  }

  .logo-preview {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 125px;
    height: 125px;

    img {
      display: block;
      max-width: 125px;
      max-height: 125px;
      width: auto;
      height: auto;
    }

  }

}
