.split-layout {

  height: 100%;

  .logo {
    margin: 20px 0 50px 0;
  }

  .content {

    &--right {

      width: 35vw;
      background-size: cover;
      height: 100vh;
      overflow: hidden;

      &-actions {

        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 35px 30px 30px;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          opacity: 0.5;
          margin-right: 20px;
        }

        .button.primary {
          border: 1px solid rgba(0,0,0,0.1);
          background-color: transparent;
          color: #222222;
          max-width: 11rem;
          padding: 10px 20px;
          transition: border-color 0.15s ease;

          &:hover {
            text-decoration: none;
            border-color: rgba(0,0,0,0.3);
            cursor: pointer;
          }

        }

      }

    }

    &--left {
      max-width: 65vw;
      width: 100%;
      height: 100vh;
      overflow-y: scroll;
      position: relative;
      background-color: #F4F6F9;

      @include media-breakpoint-down(md) {
        max-width: 100%;
        height: auto;
        overflow-y: auto;
      }

    }

    &--inner {

      width: 100%;
      max-width: 450px;
      margin: 0 auto;
      padding: 50px 0;

      h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.5px;
        margin-bottom: 25px;
      }

      @include media-breakpoint-down(sm) {
        padding-bottom: 75px;
      }

    }

  }

}