.page-onboarding {

  h1 {
    font-weight: 500;
    font-size: 22px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #222222;
    margin-bottom: 25px;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #222222;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    line-height: 23px;
    color: #525252;
  }

  .title-block {

    &.home {

      h1 {
        font-weight: normal;
        font-size: 30px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #222222;
      }

      p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
      }

    }

    p {
      text-align: center;
      margin-bottom: 25px;
    }

  }

  .steps-list {

    max-width: 1000px;
    margin: 0 auto;

    .ant-row-flex {

      align-items: stretch;

      .ant-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &-head-title {
          display: inline-block;
          flex: 1 1;
          padding: 13px 0;
        }

        &-body {
          display: flex;
          flex-direction: column;
          height: calc(100% - 48px);
        }

        a {
          margin-top: auto;
        }

      }

    }

    .ant-col {
      margin-bottom: 20px;
    }

    &--actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 25px 0;
    }

  }

  .steps {

    max-width: 550px;
    margin: 25px auto 75px;

    .ant-steps-item-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
    }

    .ant-steps-icon {
      color: #FFF;
      font-size: 12px;
      top: 0;
    }

    .ant-steps-item-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-width: 2px;
      position: relative;
      z-index: 1;
    }

    .ant-steps-item-finish {

      .ant-steps-item-title {
        color: #00E2A7;
      }

      .ant-steps-item-icon {
        background-color: #00E2A7;
        border-color: #00E2A7;
      }

      .ant-steps-item-tail::after {
        background-color: #00E2A7;
      }

    }

    .ant-steps-item-process {

      .ant-steps-item-icon {
        background-color: #f0f2f5;
        border-color: #00E2A7;
      }

      .ant-steps-icon {
        color: #f0f2f5;
      }

      .ant-steps-icon-dot {
        background: #00E2A7;
      }

      & > .ant-steps-item-tail::after {
        background-color: #CDD1D8;
      }

    }

    .ant-steps-item-wait {

      .ant-steps-item-icon {
        background-color: #f0f2f5;
        border-color: #CDD1D8;
      }

      .ant-steps-icon {
        color: #f0f2f5;
      }

      & > .ant-steps-item-tail::after {
        background-color: #CDD1D8;
      }

    }

    .ant-steps-item-icon {
      width: 20px;
      height: 20px;
    }

    .ant-steps-item-tail {
      top: 5px;

      &:after {
        height: 2px;
      }

    }

    .ant-steps-label-vertical .ant-steps-item-tail {
      margin-left: 45px;
      padding: 3.5px 0px;
    }

    &.step-1 {

      .ant-steps-item-process {

        & > .ant-steps-item-tail::after {
          background: linear-gradient(90deg, rgba(0,226,167,1) 33%, rgba(205,209,216,1) 33%);
        }

      }

    }

    &.step-2 {

      .ant-steps-item-process {

        & > .ant-steps-item-tail::after {
          background: linear-gradient(90deg, rgba(0,226,167,1) 66%, rgba(205,209,216,1) 66%);
        }

      }

    }

  }

  .skip {
    display: block;
    text-align: center;
    margin: 25px auto;
  }

  .module {

    &--header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 25px 0px;
    }

    &--table {

      display: flex;
      flex-direction: column;

      &-row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e8e8e8;

        &:last-of-type {
          border-bottom: none;
        }

      }

      &-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding: 16px 16px;
      }

      &-header {
        background-color: #fafafa;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-align: left;
        font-size: 17px;
        border-top: 1px solid #e8e8e8;
      }

    }

    &--buttons {
      padding: 16px 24px;
      border-top: 1px solid #e8e8e8;

      button {
        margin-right: 10px;
      }

      &.split {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          margin-right: 0;
        }

      }

    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      padding: 0px 16px;
    }

  }

}
