.color-input-field {

  display: flex;
  flex-direction: row;

  &-picker {
    padding: 5px;
    background: #F8F8F8;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 2;
    width: auto;
  }

  .swatch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;

    .color {
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }

    .text {
      display: block;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-left: 5px;
      padding: 0 5px;
      color: #444444;
    }

  }

  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .ant-input-group-addon {
    padding: 0 1px;
  }

}
