.unlock-feature {

  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 775px;
  width: 100%;

  i {
    font-size: 36px;
    color: #222222;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    color: #222222;
    margin: 15px auto 20px auto;
  }

  .button.primary {
    margin: 0 auto;
    font-size: 13px;
    line-height: 16px;
    padding: 10px 25px;
  }

}
