.page-reset-password {

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -.5px;
    margin-bottom: 25px;
  }

  .ant-form-item-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.5px;
    margin-bottom: 5px;
    color: #222222;
  }

  .back {
    text-align: center;
    padding: 25px 0;
  }

}
