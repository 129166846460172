.page-milestones {

  h3 {
    font-size: 16px;
  }

  ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul {

    .icon-drag-grid {
      transition: opacity 0.3s ease;
    }

    &.drag-disabled {

      .icon-drag-grid {
        opacity: 0.3;
        pointer-events: none;
      }

    }

  }

  li {
    margin-bottom: 15px;
    transition: opacity 0.3s ease;

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }

  }

  .milestone {

    &--container {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &--order {
      display: flex;
      flex: 1;
      flex-shrink: 0;
      flex-direction: column;
      margin-right: 15px;
      width: 100%;

      li {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        span {
          font-size: 24px;
          font-weight: 500;
          border-bottom: 2px solid #e8e8e8;
          width: 100%;
          text-align: center;
        }

      }

    }

    &--list {
      flex-grow: 1;

      &.empty {
        width: 100%;
        border: 2px dashed #D8D8D8;
        min-height: 64px;
        padding-top: 10px;
      }

    }

  }

  .button-row {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    justify-content: space-between;
    align-items: center;
  }

  .ant-card {
    border-radius: 0;

    .ant-card-head-title {
      font-size: 20px;
    }

    .ant-card-body {
      padding: 0;
    }

    .card--inner {

      padding: 24px;

      h4 {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1D1D1D;
        margin-bottom: 10px;
        text-align: left;
      }

      button {
        width: 100%;
        margin: 15px 0 15px 0;
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
      }

      p.subtext {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        color: #8B8B8B;
      }

    }

    hr {
      border: none;
      border-top: 1px solid #E0E0E0;
      outline: none;
      padding: 0;
      margin: 0;
    }

  }

  .alert {

    position: relative;
    overflow: visible;

    button {
      z-index: 1;
    }

    button:not([disabled]) {
      background-color: #00E2A7;
      border-color: #00E2A7;
      color: #222222;

      &:hover {
        border-color: #00CA95;
        background-color: #00CA95;
      }

    }

    button[disabled] {
      background-color: #D4D4D4;
      border-color: #D4D4D4;
      color: #999999;
    }

    &:not(.disabled) {

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #00E2A7;
        border-radius: 4px;
        transform-origin: center center;
        animation: rtbPulse 1.5s infinite;
        transition: all 5s ease;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #00E2A7;
        border-radius: 4px;
        transform-origin: center center;
        animation: rtbPulse 1.5s infinite 0.75s;
        transition: all 5s ease;
      }

    }

  }

  @keyframes rtbPulse {
    from {
      opacity: 0.5;
      transform: scale3d(1, 1, 1);
    }
    to {
      opacity: 0;
      transform: scale3d(1.1, 1.4, 1);
    }
  }

}
