.page-login {

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -.5px;
    margin-bottom: 25px;
  }

  .ant-card {

    .ant-form-item-label {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.5px;
      margin-bottom: 5px;
      color: #222222;
    }

    .login--forgot {
      float: right;
    }
    .login--button {
      font-size: 14px;
      padding: 15px 20px;
      height: auto;
      width:100%;
      margin-top:20px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #5285FF;
      border-color: #5285FF;
    }

    .ant-checkbox-inner::after {
      left: 22%;
    }

    label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked span {
      color: #626262;
    }

  }

  .signup {
    text-align: center;
    padding: 25px 0;
  }

}
