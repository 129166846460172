.digs-table {

  .search {

    input {
      background: #FFFFFF;
      border: 1px solid #DBE0E7;
      box-sizing: border-box;
      border-radius: 21px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #959595;
    }

    .ant-input {
      min-width: 325px;
    }

    .ant-btn {
      border: none;
      padding: 0;
      margin: 0 5px 0 0;
      line-height: 2.5;
    }

  }

  .table-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;

    .ant-input-affix-wrapper .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
      z-index: 1;
    }

    .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
      border-color: #5285FF;
      border-right-width: 1px !important;
    }

  }

  .ant-table {

    color: #222222;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border: none;
      padding: 5px;
      white-space: nowrap;
    }

    .ant-table-tbody > tr {

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      .editable-cell-value-wrap {

        .icon-pencil {
          opacity: 0;
          margin-left: 7px;
          color: #B1B1B1;
          transition: opacity 0.15s ease;

          &:hover {
            cursor: pointer;
            color: #222222;
          }

        }

      }

      &:hover {
        // cursor: pointer;

        &:not(.ant-table-expanded-row) > td {
          background-color: #FFFFFF;
          border-color: #9EBBFF;
        }

        .editable-cell-value-wrap {

          .icon-pencil {
            opacity: 1;
          }

        }

      }

    }

    .ant-table-thead > tr > th {
      padding-top: 0;
      padding-bottom: 0;
      background-color: transparent;

      &:first-of-type {

        & > span {
          padding-left: 52px;
        }

      }

    }

    .ant-table-thead {

      .ant-table-header-column {

        display: inline-flex;
        margin-bottom: 6px;

        & > div {
          display: inherit;
          height: auto;
        }

      }

    }

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background-color: #222222;
      color: #FFFFFF;
      margin-right: 15px;
      position: relative;

      &.image {
        overflow: hidden;
      }

      span {
        position: relative;
        top: 1px;
      }

      img {
        display: block;
        width: 100%;
      }

      &--container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }

      &--partnership {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #5285FF;
        position: absolute;
        top: -3px;
        right: -5px;

        i {
          font-size: 11px;
        }

      }

    }

    .mobile {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      .number {
        margin-right: 5px;
      }

      .verified {
        height: 14px;
        width: 14px;
        display: block;

        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
        }

      }

    }

    table {
      border-collapse: separate;
      border-spacing: 0 7px;
    }

    tr {
      background-color: transparent;

      td {
        background-color: #FFFFFF;
        height: 50px;
        transition: border-color 0.15s ease;

        &:first-child {
          border-left: 1px solid #DBE0E7;
          border-top-left-radius: 32px;
          border-bottom-left-radius: 32px;
        }

        &:last-child {
          border-right: 1px solid #DBE0E7;
          border-top-right-radius: 32px;
          border-bottom-right-radius: 32px;
        }

      }

    }

    .ant-rate {
      font-size: 16px;
      color: #FFB443;

      &-star {
        margin-right: 1px;
      }

    }

    .ant-table-column-title {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #8A8A8A;
    }

    .ant-table-tbody > tr > td {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      border-top: 1px solid #DBE0E7;
      border-bottom: 1px solid #DBE0E7;
    }

    .ant-table-thead > tr > th.ant-table-column-sort {

      background-color: transparent;

      .ant-table-column-title{
        color: #5285FF;
      }

    }

    .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover {

      &::before {
        background: transparent;
      }

      .ant-table-column-title{
        color: #5285FF;
      }

    }

    .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
    .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
      color: #5285FF;
    }

  }

  .ant-spin-blur::after {
    display: none;
  }

  .ant-table-placeholder {
    background: transparent;
    border: none;
  }

}
