.page-messages {

  .message-list {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;

    .message {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: #FFFFFF;
      margin-bottom: 10px;
      border: 1px solid #E3E3E3;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      overflow: hidden;

      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 25px 25px;

        &:hover {
          background-color: #e6f7ff;
          cursor: pointer;
        }

        &--left {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: #444444;
          flex-grow: 1;

          div {
            flex: 1;
            flex-shrink: 0;
            text-align: left;
          }

        }

        &--right {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: #9A9A9A;
        }

      }

      &__body {
        padding: 25px 25px;
        border-top: 1px solid #E3E3E3;
        width: 100%;

        p {
          font-weight: 500;
          font-size: 13px;
          line-height: 22px;
          color: #444444;
          margin-bottom: 35px;
        }

      }

      &__footer {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &--left {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          a {
            margin-right: 10px;
          }

        }

        &--right {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }

        a {
          margin-right: 10px;
        }

      }

    }

  }

  .message-table {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    &--empty {
      padding: 100px 25px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .ant-pagination {
      margin-top: 16px;
    }

  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 125px 0;
    width: 100%;
  }

}
